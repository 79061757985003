*{
    font-family: 'Rubik', sans-serif;
}

*, ::after, ::before {
    box-sizing: border-box;
}


.hero-img {
    width: 100%;
    
}


.homepage-body h1 {
    
    color: black;
    margin: auto    ;
}





.title{
    display: flex;
    margin-top: 75px;
    justify-content: right;
    
}

.title h1 {
    font-size: 104px;
    text-align: right;
    line-height: 90px;
    font-weight: 800;
    padding-top: 40px;
    padding-bottom: 20px;
    max-width: 400px;
    
}

.row p {
    margin-top: 15px;
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
}

.btn-lg {
    padding: 15px 65px;
    font-size: 24px;
}
.features{
    margin-top: 50px;
    padding: 20px;
}

.features h2 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.feature-card {
    padding: 10px;
    margin-bottom: 50px;
}


.pageTitle{
    padding: 24px;
    margin-top: 50px;
    text-align: center;
    font-family: 'Bebas Neue', sans-serif;
    
}

.pageTitle h1{
    font-weight: bold;
    font-size: 60px;
    margin: 2rem auto;
    text-align: center;
    font-family: 'Bebas Neue', sans-serif;

}



.signInButtons {
    display: flex;
    justify-content: center;
    margin: 40px;
}

.callout{
    margin-bottom: 50px;
}

.homepage .callout {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    margin-top: 35px;
    margin-bottom: 50px;
    max-width: 500px;
    text-align: right;
    
    
}

.pContainer{
    display: flex;
    justify-content: right;
}

body {
    background-color: #fafafa;
        
}