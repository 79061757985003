.managinEntries_p {
    padding-left: 4px;
    padding-right: 4px;
    display: inline-block;
    line-height: 1.5;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    
    
}

.statusAlive {
    font-size: 16px;
}

.team {
    width: 35px;
    
    
}

.deadline{
    font-weight: bold;
}

.form_select {
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
    border-color: rgb(206, 212, 218);
    border-width: 1px;
    border-radius: 8px;
    font-size: 14px;
    padding: 14px 16px 14px 17px;
    font-weight: 600;
    box-shadow: none !important;
    width: 112px;
    display: inline;
}

.pickRow td {
    padding: 15px;
}

.pickRow td:first-child{
    padding-left: 25px !important;
}


.entries th {
    text-align: center;
    padding: 10px;
}

.entry{
    text-align: left !important; 
}



@media (max-width: 768px) {
    
    .form_select {
        
        padding: 8px 8px 8px 12px;
    }

    .entries th{
        padding: 2px;
        padding-bottom: 12px;
        padding-top: 12px;

    }

    th:first-child {
        padding-left: 10px !important;
    }
}
