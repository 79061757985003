.superNav {
    flex-grow: 3;
    text-align: center;
    display: none;
}


.superNavItem:first-child {
    margin-right: 32px;
}

.navbar a {
    text-decoration: none;
}

.superNavItem {
    display: inline-block;
    font-weight: 500;
    color: #5D6268;
    padding-bottom: 29px;
    padding-top: 28px;
    border-bottom: solid 2px transparent;
}


.navbar-light .navbar-toggler {
    color: rgba(0,0,0,.55);
    border-color: rgba(0,0,0,.1);
}



.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: box-shadow .15s ease-in-out;
}


.navbar .top-navigation {
    vertical-align: baseline;
    margin-left: auto;
}

.fas{
    color: black;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3e%3cpath stroke="rgba(0, 0, 0, 0.55)" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"/%3e%3c/svg%3e');
}

.navbar #usernameDropdown {
    font-family: "Bebas Neue", sans-serif;
    font-size: 28px;
    padding: 8px 23px 9px 23px !important;
}

.navbar #usernameDropdown.btn-plain {
    padding: 8px 5px 9px 5px !important;
}

.navbar .authentication {
    margin-left: 40px;
    float: right;
    margin-top: 0px;
}

.navbar-nav {
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.pool-nav-tabs .tab-item .nav-link {
    padding: 15px 0px;
    margin: 0 15px;
    color: #5D6268;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}

.hidden {
    display: none;
}




@media (max-width: 991px){

    

    .navbar .nav-link {
        color: #000000 !important;
        text-transform: none !important;
    }

    .navbar .nav-item, .navbar .tab-item {
        border-bottom: solid 1px #e5e5e5;
        margin-left: 0 !important;
    }

    .pool-nav-tabs .tab-item {
        padding-left: 10px;
        padding-right: 10px;
    }

    .navbar .navbar-collapse {
        position: absolute;
        width: 100%;
        max-width: 550px;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 20px;
        background-color: #ffffff;
        float: none;
        z-index: 1000;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
        border: solid 1px #eeeeee;
        padding-left: 0px;
        padding-right: 0px;
    }

    .navbar .navbar-nav {
        margin-bottom: 0px !important;
    }

    .navbar #usernameDropdown.btn-plain {
        border-color: rgba(0,0,0,.1);
        box-shadow: 0px 0px 4px rgb(0 0 0 / 10%) !important;
    }
    

    .navbar .authentication.logged-in #usernameDropdown {
        padding: 11.3px 15px 11.3px 15px !important;
    }

    .navbar #usernameDropdown {
        font-size: 14px;
    }

    .navbar .authentication {
        margin-left: 5px;
        display: inline-block;
        float: none;
        margin-bottom: 3px;
        
    }

    .navbar-toggler {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1) !important;
        border-width: 0px;
        border-radius: 8px;
        padding: 0.43rem 0.75rem;
    }

    .navbar .top-navigation .navbar-toggler {
        vertical-align: bottom;
        margin-bottom: 4px;
    }

    
    .navbar .authentication.logged-in .fa-user {
        margin-right: 0px;
    }

    .superNav{
        display: none;
    }

    
}

@media (min-width: 991px){

    header.overview{
        background-color: #ffffff;
        padding-top: 0px !important;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
        position: fixed !important;
        top: 0 !important;
        z-index: 1039;
        width: 100%;
    }

    .navbar-nav {
        width: 100%;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .top-navigation .container-nav li:first-child a {
        margin-left: 0px;
    }

    .pool-nav-tabs .tab-item .nav-link {
        padding-bottom: 29.5px;
    }

    .pool-nav-tabs .tab-item .nav-link {
        padding-bottom: 29.5px;
    }

    .top-navigation .container-nav {
        margin-top: 75px !important;
    }
    .top-navigation .container-nav {
        display: none;
    }
    .d-lg-inline-block {
        display: inline-block!important;
    }

    .navbar .authentication.logged-in {
        display: flex;
        flex-direction: row-reverse;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }

    #navbarDropdown {
        display: none !important;
    }
    
    .superNav {
        display: inline-block;
    }

    .top-navigation .authentication.logged-in #usernameDropdown {
        background-color: inherit;
        color: #000000 !important;
        border-width: 0px;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
        text-align: right;
        display: flex;
        align-items: center;
        margin-top: -35px !important;
    }
    .top-navigation .authentication.logged-in #usernameDropdown {
        margin-top: -10px !important;
    }
    .navbar .top-navigation {
        width: 120px;
        position: relative;
    }
}

@media (max-width: 576px)
    {.top-navigation {
        white-space: nowrap;
        margin-left: 15px;
    }}
