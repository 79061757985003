.AppHeader__HeaderWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 8997;
    background-color: rgb(255, 255, 255);
}

.Container__HeaderContainer{
    height: 80px;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 3px;
}

.Container__HeaderContent {
    width: 100%;
    max-width: 1224px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 0px 16px;
}

.Container__HeaderLeftSlot{
    /* width: calc(25%); */
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.Container__HeaderMiddleSlot{
    display: flex;
    height: 100%;
    width: calc(50%);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;

    
}


.SecondaryNav{
    height: 80px;
}

.HeaderNav{
    display: flex;
}

.SecondaryNavItem{
    margin-right: 32px;
}

.SecondaryNavLink{
    background: none;
    border-width: 0px 0px 2px;
    border-top-style: initial;
    border-right-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    border-image: initial;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    cursor: pointer;
    text-decoration: none;
    height: 100%;
    ;
}

.SecondaryNavLink:hover {
    border-bottom-color: rgb(220,53,69);
    border-bottom-style: solid;
}


.SecondaryNavLink:visited {
    color: rgb(93, 98, 104);
}

.SecondaryNav .active {
    color: black;
    border-bottom-color: rgb(220,53,69);
    border-bottom-style: solid;
  }



.SecondaryNavLink:hover:not(:disabled) {
    color: rgb(8, 10, 13);
    border-bottom-color: rgb(220,53,69);
}

.UserPanel__UserPanelContainer{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
}

.icon{
    height: 15px;
    width: 15px;
}


.components__UserMenuTriggerMobile{
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    text-align: center;
    background-color: rgb(220,53,69);
    height: 48px;
    width: 48px;
    padding: 0px;
}

.MediaQuery__MobileOnly{
    display: none;
}
.components__UserMenuContainer {
    position: relative;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}



@media (max-width: 600px){

    .Container__HeaderMiddleSlot{
        display: none;
    }

    .MediaQuery__MobileOnly{
        display: inline;
    }

    .MediaQuery__DesktopAndTablet{
        display: none;
    }
}

