#picksTable td {
    padding: 3px;
}

#picksTable .headCell {
    font-family: Arial Narrow, Segoe UI, Arial;
}

.sticky {
    top: -1px;
    
    z-index: 99;
}

.n {
    width: 150px;
    overflow: hidden;
    font-weight: bold;
}

.pts{
    display: block;
    font-style: normal;
    font-weight: normal;
    color: #777777;
    min-width: 150px;
}

#picksTable{
    border-spacing: 0px 6px;
    border-collapse: separate;
}

.loser-class{
    background-color: #FF9F9F;
    border: solid 1px;
    border-color: #CC0000;
    border-left: 0px;
    
}

.winner-class {
    background-color: #A7FFA7;
    border: solid 1px;
    border-color: #006600;
    border-left: 0px;
    
}

.undefined-class {
    background-color: #f2f2f2;
    border: solid 1px;
    border-color: #8c8c8c;
    border-left: 0px;
    
}


.first-col-class{
    border-left: solid 1px;
}

.t{
    width: 35px;
    height: 36px;
    margin: auto;
}

.t img{
    width: 100%;
}

.tie {
    font-family: Arial Narrow, Segoe UI, Arial;
    text-align: center;
}

.tb {
    font-size: 11px;
    color: #888888;
    padding-left: 3px;


}