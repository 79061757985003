.responsive-calendar {
    padding: 0px;
    padding-top: 5px;
}

.responsive-calendar .day-headers {
    margin-top: 10px;
}

.responsive-calendar .day-headers, .responsive-calendar .days {
    font-size: 0;
}

.responsive-calendar .day.header {
    border-bottom: 1px gray solid;
}

.responsive-calendar .day.Sat a {
    border-right: solid 0px #eee;
}

.responsive-calendar .day {
    display: inline-block;
    position: relative;
    font-size: 14px;
    width: 14.285714285714286%;
    text-align: center;
    padding: 0;


}

.responsive-calendar .day a:hover {
    background-color: #d6d6d6;
    text-decoration: none;
}


.responsive-calendar .day a {
    border-right: solid 1px gray;
    border-bottom: solid 1px gray;
    display: block;
    padding: 20% 0 20% 0;


}

.responsive-calendar .day.not-current a {
    color: #ddd;
}

.responsive-calendar .day .badge {
    padding: 1px 2px 3px 3px;
    border-radius: 0px;
    border-bottom-left-radius: 5px;
    background-color: #f89406;
    top: 0px;
    right: 1px;
    font-size: 10px;
    z-index: 1;
    position: absolute;
}

.responsive-calendar .day.Active a {
    background-color: #d3ecfc;
    color: #000;
    font-weight: bold;
    border-right: solid 1px gray;
    border-bottom: solid 1px gray;
}

.responsive-calendar .day.Active a:hover {
    background-color: #ccc;
}


/* Existing styles for animation */
.responsive-calendar .day.animate {
    opacity: 0;
    transform: rotateY(90deg);
    transform-origin: center;
    animation: rotateIn 0.5s ease-out; /* Removed 'forwards' */
    animation-fill-mode: forwards; /* Retain styles from last keyframe */
}

@keyframes rotateIn {
    from {
        opacity: 0;
        transform: rotateY(90deg);
    }
    to {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

/* Stagger the animation for each day with a small delay */
.responsive-calendar .days .day.animate.item-1 { animation-delay: 0.01s; }
.responsive-calendar .days .day.animate.item-2 { animation-delay: 0.01s; }
.responsive-calendar .days .day.animate.item-3 { animation-delay: 0.01s; }
.responsive-calendar .days .day.animate.item-4 { animation-delay: 0.01s; }
.responsive-calendar .days .day.animate.item-5 { animation-delay: 0.01s; }
.responsive-calendar .days .day.animate.item-6 { animation-delay: 0.01s; }
.responsive-calendar .days .day.animate.item-7 { animation-delay: 0.01s; }


.responsive-calendar .days .day.animate.item-8 { animation-delay: 0.10s; }
.responsive-calendar .days .day.animate.item-9 { animation-delay: 0.10s; }
.responsive-calendar .days .day.animate.item-10 { animation-delay: 0.10s; }
.responsive-calendar .days .day.animate.item-11 { animation-delay: 0.10s; }
.responsive-calendar .days .day.animate.item-12 { animation-delay: 0.10s; }
.responsive-calendar .days .day.animate.item-13 { animation-delay: 0.10s; }
.responsive-calendar .days .day.animate.item-14 { animation-delay: 0.10s; }


.responsive-calendar .days .day.animate.item-15 { animation-delay: 0.20s; }
.responsive-calendar .days .day.animate.item-16 { animation-delay: 0.20s; }
.responsive-calendar .days .day.animate.item-17 { animation-delay: 0.20s; }
.responsive-calendar .days .day.animate.item-18 { animation-delay: 0.20s; }
.responsive-calendar .days .day.animate.item-19 { animation-delay: 0.20s; }
.responsive-calendar .days .day.animate.item-20 { animation-delay: 0.20s; }
.responsive-calendar .days .day.animate.item-21 { animation-delay: 0.20s; }


.responsive-calendar .days .day.animate.item-22 { animation-delay: 0.30s; }
.responsive-calendar .days .day.animate.item-23 { animation-delay: 0.30s; }
.responsive-calendar .days .day.animate.item-24 { animation-delay: 0.30s; }
.responsive-calendar .days .day.animate.item-25 { animation-delay: 0.30s; }
.responsive-calendar .days .day.animate.item-26 { animation-delay: 0.30s; }
.responsive-calendar .days .day.animate.item-27 { animation-delay: 0.30s; }
.responsive-calendar .days .day.animate.item-28 { animation-delay: 0.30s; }


.responsive-calendar .days .day.animate.item-29 { animation-delay: 0.40s; }
.responsive-calendar .days .day.animate.item-30 { animation-delay: 0.40s; }
.responsive-calendar .days .day.animate.item-31 { animation-delay: 0.40s; }
.responsive-calendar .days .day.animate.item-32 { animation-delay: 0.40s; }
.responsive-calendar .days .day.animate.item-33 { animation-delay: 0.40s; }
.responsive-calendar .days .day.animate.item-34 { animation-delay: 0.40s; }
.responsive-calendar .days .day.animate.item-35 { animation-delay: 0.40s; }


.responsive-calendar .days .day.animate.item-36 { animation-delay: 0.50s; }
.responsive-calendar .days .day.animate.item-37 { animation-delay: 0.50s; }
.responsive-calendar .days .day.animate.item-38 { animation-delay: 0.50s; }
.responsive-calendar .days .day.animate.item-39 { animation-delay: 0.50s; }
.responsive-calendar .days .day.animate.item-40 { animation-delay: 0.50s; }
.responsive-calendar .days .day.animate.item-41 { animation-delay: 0.50s; }
.responsive-calendar .days .day.animate.item-42 { animation-delay: 0.50s; }


/* Add more rules for additional days as needed */


  
  