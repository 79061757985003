.homeBox {
    background-color: #f0f0f0;
    border: solid 1px #dddddd;
    width: 46%;
    cursor: hand;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 6px;
    float: right;
    padding-right: 20px;
    position: relative;
}

.awayBox {
    background-color: #f0f0f0;
    border: solid 1px #dddddd;
    width: 46%;
    cursor: hand;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 6px;
    float: left;
    padding-right: 20px;
}

.homeBox, .awayBox {
    width: 49.5%;
}

.homeBox.selected {
    background-color: rgb(255, 255, 170);
  }


.homeBox img, .awayBox img {
    max-width: 65px;
    padding-left: 10px;
    padding-right: 10px;
}

.homeBox input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

.teamName {
    display: inline;
    font-size: 15px;
}

.teamAbbr {
    display: none;
}

.teamRecord {
    color: #888888;
    font-size: 11px;
    padding-left: 5px;
}

.teamLocation{
    color: #888888;
    font-size: 11px;
    display: block;
}

.h {
    max-width: 65px;
}

.day {
    font-size: 11px;
    text-align: center;
    color: #666666;
    padding: 5px;
}

.rules li {
    padding-bottom: 5px;
    list-style-type: disc;
}


@media(max-width: 767px){
    .teamName{
        display: inline;
    }
}


@media(max-width: 525px){
    .teamName{
        display: block;
    }
}



@media(max-width: 464px){
    .teamName {
        display: none;
        
    }
    .teamAbbr {
        display: block;
        font-weight: bold;
    }
    
}