.label-style {
    position: absolute;
    z-index: 1;
    display: block;
    padding-left: 16px;
    cursor: text;
    transition: all 250ms ease-in-out 0s;
    top: 14px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgb(148, 154, 157);
}

.input-style {
    position: relative;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: rgb(8, 10, 13);
    border: 0px;
    background: transparent;
    display: block;
    outline: none;
    padding: 24px 16px 8px;
    width: 100%;
    height: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    min-height: auto;
    resize: none;
}

.page-container{
    box-sizing: border-box;
    width: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    padding-bottom: 80px;
    
}
.nameTaken{
    color: red;
    
}




.layout-body{
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.layout-form{
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    
}

.layout-row{
    width: 392px;
    position: relative;
    background: rgb(240, 242, 245);
    border-radius: 8px;
    height: 60px;
    margin: 10px;
}

.layout-row-button{
    width: 392px;
    margin: 10px;
}

.layout-button{
    width: 100%;
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    text-align: center;
    background-color: rgb(0, 52, 206);
    padding: 0px 22px;
    height: 48px;
    color: white;
}


@media (max-width: 402px){
    .layout-row {
        width: 100%;
    }

    .layout-form{
        width: 100%;
    }

    .layout-body{
        width: 100%;
        padding: 0px 16px 20px;
    }
}