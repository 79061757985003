.poolRegister {
    margin: auto;
    text-align: center;
}

h1 {
    font-family: "Bebas Neue", sans-serif;
    font-size: 64px;
    text-transform: uppercase;
    line-height: 56px;
    padding-top: 40px;
    padding-bottom: 48px;
    margin-top: 0px
;
   
    
}

h3 {
    font-family: "Bebas Neue", sans-serif;
    font-size: 24px;
    margin-bottom: 32px;
}

.poolRegister .settings {
    max-width: 600px;
    margin: auto;
    text-align: left;
}

.poolRegister .settings label {
    position: absolute;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #8B8E97;
    margin-left: 26px;
    pointer-events: none;
}

.poolRegister .settings .fa-help {
    float: right;
    margin-top: 24px;
    margin-right: -30px;
}

.poolRegister .settings input.form-control {
    background-color: #f0f2f5;
    border-radius: 8px;
    font-size: 16px;
    padding: 30px 15px 10px 25px;
    font-weight: 600;
    margin-bottom: 8px;
}

.poolRegister .settings select.form-select {

    padding: 30px 38px 10px 25px;
    font-family: Inter, sans-serif;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
    background-color: #f0f2f5;
}

.popover-header {
   
    font-size: 20px;
    font-family:  "Bebas Neue", sans-serif;
    background-color: white;
    border: none;
    padding-bottom: 0px;
    
  }
  
  .popover-body {
   
    padding-top: 0px;
    font-size: 14px;
  }


.visibility .fas {
    position: absolute;
    margin-top: 35px;
    margin-left: 25px;
}

.visibility select {
    padding-left: 44px !important;
}

.poolRegister .btn {
    margin-top: 28px;
    margin-bottom: 28px;
    max-width: 600px;
    width: 100%;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    
    

}


.small-text {
    color: #5D6268;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    
}

.text-ul {
    padding-left: 2rem;
    padding-top: 5px;
    list-style: disc ;
    line-height: 1.5;
    
    
}

@media (max-width: 576px){
.poolRegister .settings {
    max-width: 87%;
}}

