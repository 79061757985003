#advOptionsBtn {
    float: right;
    margin-top: 15px;
}


#ytdTable th, #ytdUser th, #weekUser th {
    padding: 8px;
    text-align: center;
    padding-right: 11px;
}

#ytdTable td, #ytdUser td, #weekUser td {
    border-top: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: #f9f9f9;
}

tbody {
    border-top-style: solid;
    border-bottom-style: solid;   
}

table.dataTable thead .sorting_asc {
    background-image: url(https://www.runyourpool.com/assets/plugins/datatables/images/sort_asc.png);
}

table.dataTable thead .sorting {
    background-image: url(https://www.runyourpool.com/assets/plugins/datatables/images/sort_both.png);
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
    background-repeat: no-repeat;
    background-position: center right;
}





@media (min-width: 992px){
    .optionsPanel {
        margin-top: -115px !important;
    }
}